import * as yup from 'yup';

export interface JobLaborDetailDto {
  id?: number;
  laborClassificationId: number;
  laborClassificationName?: string;
  laborDescription?: string;
  straightTimeRate: number;
  timeAndAHalfRate: number;
  doubleTimeRate: number;
}

export const jobLaborDetailInit: JobLaborDetailDto = {
  laborClassificationId: 0,
  laborClassificationName: undefined,
  laborDescription: undefined,
  straightTimeRate: 0.0,
  timeAndAHalfRate: 0.0,
  doubleTimeRate: parseFloat('0.00')
};

export const jobLaborDetailValidationSchema = yup.object({
  laborClassificationId: yup
    .number()
    .typeError('Labor Classification is required.')
    .min(1, 'Labor Classification is required.')
    .required('Labor Classification is required.'),
  laborDescription: yup.string().nullable().max(200, 'Labor Description cannot be more than 200 characters.'),
  straightTimeRate: yup
    .number()
    .min(0.0, 'Straight Time must be between $0.00 and $999,999,999,999.99.')
    .max(999_999_999_999.99, 'Straight Time must be between $0.00 and $999,999,999,999.99.')
    .required('Straight Time is required.'),
  timeAndAHalfRate: yup
    .number()
    .min(0.0, 'Time ½ must be between $0.00 and $999,999,999,999.99.')
    .max(999_999_999_999.99, 'Time ½ must be between $0.00 and $999,999,999,999.99.')
    .required('Time ½ is required.'),
  doubleTimeRate: yup
    .number()
    .min(0.0, 'Double Time must be between $0.00 and $999,999,999,999.99.')
    .max(999_999_999_999.99, 'Double Time must be between $0.00 and $999,999,999,999.99.')
    .required('Double Time is required.')
});
